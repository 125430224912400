<script>
import mixCommons from "../mixins/mixCommons";
export default {
  mixins: [mixCommons],
  computed: {
    date() {
      return this.$store.getDateCalendar;
    },
    tabSelected() {
      return this.$store.getTabSelected;
    },
    merchantTags() {
      return this.$store.getMerchantTags;
    },
    selectedPdvs() {
      return this.$store.getSelectedMerchants;
    },
    elements() {
      return this.$store.getHideHeader;
    },
    hasToSelectFirstItem() {
      if (this.$store.getHasToSelectFirstItem && this.userList[0]) {
        this.$store.setRefreshUserList( this.userList[0].users);
      }
      return this.$store.getHasToSelectFirstItem;
    },
    hasToSelectFirstClientItem() {
      if (this.$store.getHasToSelectFirstClientItem) {
        this.$store.refreshClientList( this.clientList[0].customers);
      }
      return this.$store.getHasToSelectFirstClientItem;
    },
    hasMultipleQuantityOfList() {
      return this.productLists.length > 1;
    },
    productLists() {
      return this.$store.getProductLists;
    },
    hasMultipleQuantityOfProviderList() {
      return this.providerLists.length > 1;
    },
    providerLists() {
      /* eslint-disable */
      // const list = this.$store.getProviderLists.sort((a, b) => b.name.localeCompare(a.name));
      return this.$store.getProviderLists;
      /* eslint-enable */
    },
    menuEngineeringLists() {
      /* eslint-disable */
      const original = this.$store.getMenuEngineeringLists || [];
      const list = [...original].sort((a, b) => a.shopCode - b.shopCode);
      return list;
      /* eslint-enable */
    },
    userList() {
      return this.$store.getUserList;
    },
    PDVList() {
      return this.$store.getPDVList;
    },
    user() {
      return this.$store.getAuthUser;
    },
    settingList() {
      return this.$store.getSettingList;
    },
    myPaymentsList() {
      return this.$store.getMyPaymentsList;
    },
    clientList() {
      return this.$store.getClientList;
    },
    hasMultipleQuantityOfUserList() {
      return this.userList.length > 1;
    },
    hasMultipleQuantityOfSettingList() {
      return this.settingList.length > 1;
    },
    hasMultipleQuantityOfClientList() {
      return this.clientList.length > 1;
    },
    isAnyMerchantTagSelected() {
      var response = false;
      this.merchantTags?.forEach((item) => {
        if (item.selected == true)
          response = true;
      });
      return response;
    }
  },
  methods: {
    sortMerchantTagList(list){
      var sortedList;
      if (!list || list.length === 0) { //Si la lista esta vacia devuelvo un array vacio
        sortedList = [];
      } else if (list.length === 1) { //Si la lista tiene un elemento devuelvo esa misma lista
        sortedList = [...list];
      } else { //Si tiene mas de 1 elemento, ordeno alfabeticamente, como las listas pueden diferir en el nombre de la variable, diferencio esos casos
        if(list[0].name != null && list[0].name != undefined)
          sortedList = list.sort((a, b) => a.name.localeCompare(b.name));
        else if(list[0].listName != null && list[0].listName != undefined)
          sortedList = list.sort((a, b) => a.listName.localeCompare(b.listName));
        else if(list[0].merchantName != null && list[0].merchantName != undefined)
          sortedList = list.sort((a, b) => a.merchantName.localeCompare(b.merchantName));
      }
      return sortedList
    },
    showCalendar() {
      if (!navigator.onLine ) return
      /* eslint-disable */
      //$("#modalCalendar").modal("open");
      var calendar = document.getElementById("modalCalendar");
      var instance = M.Modal.init(calendar);
      instance.open();
      /* eslint-enable */
    },
    unselectAllShopFilter() {
      if (this.isAnyMerchantTagSelected == true) {
        this.merchantTags.forEach((item) => {
          if (item.selected == true)
            this.selectPdv(item);
        });
      }
    },
    selectPdv(pdv) {
      this.$store.toggleMerchantSelected(pdv);
      const updatedTags = this.merchantTags.map((item) => {
        if (!item.selected && item.shopCode === pdv.shopCode) {
          item.selected = true;
        } else if (item.selected && item.shopCode === pdv.shopCode) {
          item.selected = false;
        }
        return item;
      });

      this.user.merchantTags = updatedTags;
      let merchantTags = this.getMerchantTags();
      if (merchantTags) {
        merchantTags.forEach((x) => {
          const foundTag = updatedTags.find((y) => y.shopCode == x.shopCode);
          if (foundTag) {
            x.selected = foundTag.selected;
          }
        });
        this.setMerchantTags({ merchantTags: merchantTags });
        this.$store.CALL_MERCHANTS("CALL_MERCHANTS");
      }
    },
    changeTab(tab) {
      if(!navigator.onLine && this.excludedOfflineRoutes.includes(this.$route.fullPath)) return 
      if (this.date.indexOf("Seleccione") < 0) {
        this.$store.updateDate("");
        this.formatDates("", "");
        this.dateOne = "";
        this.dateTwo = "";
      }
      this.setPeriodSelection(tab);
      return this.$store.updateTabSelected(tab);
    },
    onProductsListChanged(item) {
      this.$store.setRefreshProductList(item);
    },
    onProvidersListChanged(item) {
      this.$store.setRefreshProviderList( item.shopCode);
    },
    onPDVListChanged(item) {
      this.$store.setRefreshPDVList( item.shopCode);
    },
    onMenuEngineeringListChanged(item) {
      this.$store.setRefreshMenuEngineeringList( item.shopCode);
    },
    onUserListChanged(item) {
      if (item) {
        this.$store.setRefreshUserList(
          this.userList.filter((t) => t.shopCode === item.shopCode)[0].users
        );
        this.$store.setRefreshSelectedShopCode( item.shopCode);
      } else {
        this.$store.refreshUserList( []);
        this.$store.setRefreshSelectedShopCode( null);
      }
    },
    onSettingListChanged(item) {
      if (item) {
        this.$store.setRefreshSelectedShopCode( item.code);
      } else {
        this.$store.setRefreshSelectedShopCode( null);
      }
    },
    onMyPaymentsListChanged(item) {
      if (item){
        this.$store.setCurrentShopMyPayments(item.name, item.code);
      } else {
        this.$store.setRefreshSelectedShopCode(null);
      }
    },
    onCustomerListChanged(item) {
      if (item) {
        this.$store.setRefreshClientList(
          this.clientList.filter((t) => t.shopCode === item.shopCode)[0]
            .customers
        );
        this.$store.setRefreshSelectedShopCode(item.shopCode);
      } else {
        this.$store.refreshClientList([]);
        this.$store.setRefreshSelectedShopCode(null);
      }
    },
    isTruncated(element) {
			return element.scrollWidth > element.clientWidth;
		},
		getMerchantNameForDropdown(shopCode, text) {
			this.$nextTick(() => {
				const h4Element = this.$refs[`merchantName_${shopCode}`];

				if (h4Element && h4Element.length > 0) {
				const element = h4Element[0];
				
				this.$nextTick(() => {
					if (this.isTruncated(element)) {
					element.title = text;
					} else {
					element.title = '';
					}
				});
				}
			});
			return '';
		},
  },
};
</script>
