<template>
  <div id="modalUserCreationEdition" class="modal modal-block-outside" style="overflow-y: auto !important"
    @keydown.esc="closeModal()">
    <div class="modal-header">
      <div class="image-title">
        <h4>{{ getTitle }} usuario</h4>
        <p>Fácil y Simple</p>
      </div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain">
          <div class="itemForm">
            <label for>Nombre de Usuario:</label>
            <input v-if="isUserNameEditable" v-model="/*eslint-disable*/localUser.username" @change="onHandleChange()"
              type="text" maxlength="14" placeholder="Nombre de Usuario" />
            <input v-else readonly class="itemFormReadOnly" v-model="localUser.username" @change="onHandleChange()"
              type="text" maxlength="14" placeholder="Nombre de Usuario" />
          </div>
          <div class="itemForm">
            <label for>Mail de acceso web:</label>
            <input v-if="isUserMailEditable" v-model="localUser.mail" @change="onHandleChange()" type="text"
              placeholder="usuario@correo.com" />
            <input v-else v-model="localUser.mail" @change="onHandleChange()" type="text"
              placeholder="usuario@correo.com" readonly class="itemFormReadOnly" />
          </div>
          <div class="itemForm itemFormUserCreation">
            <label class="switchTitle" for>Seleccione los comercios a vincular:</label>
            <div class="switch" v-for="(op, ind) in selectedPdvs" :key="ind">
              <label>
                <a class="switchLabelExtended">{{
                  `${op.merchantName} (${op.shopCode})`
                }}</a>
                <input :checked="isShopSwitchChecked(op.shopCode)" @change="onHandleChange()"
                  @click="onHandleShopSwitch(op.shopCode)" type="checkbox" />
                <span class="lever"></span>
              </label>
            </div>
          </div>
          <div class="itemForm itemFormUserCreation">
            <label class="switchTitle" for>Seleccione los roles del usuario:</label>
            <div class="switch" v-for="(op, ind) in roles" :key="ind">
              <label>
                <a class="switchLabelExtended">{{ `${op.roleName}` }}</a>
                <input :key="reRenderFlag" :checked="isRoleSwitchChecked(op.backendId)" @change="onHandleChange()"
                  @click="onHandleRoleSwitch(op.backendId, op.subRoles)" type="checkbox" />
                <span class="lever"></span>
                <div class="switch" v-if="op.subRoles" v-for="(subRole, subRoleIndex) in op.subRoles"
                  :key="subRoleIndex">
                  <label>
                    <a class="switchLabelExtended subRoles">{{
                      `${subRole.roleName}`
                    }}</a>
                    <input :key="reRenderFlag" :checked="isRoleSwitchChecked(subRole.backendId)"
                      @change="onHandleChange()" @click="onHandleRoleSwitch(subRole.backendId)" type="checkbox" />
                    <span class="lever"></span>
                  </label>
                </div>
              </label>
            </div>
          </div>
          <div class="itemForm">
            <div class="switch">
              <label class="switchTitle">
                <a class="switchLabelExtended">Recibir correo de cierre de caja</a>
                <input v-model="localUser.hasToReceiveCashboxClosureReports" @change="onHandleChange()"
                  type="checkbox" />
                <span class="lever"></span>
              </label>
            </div>
          </div>
        </article>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a v-if="mode === 'EDIT'" class="deleteProduct" @click.prevent="deleteUser">
          <span class="icon-Bistro-_Trash_bin"></span><span>Eliminar Usuario</span>
        </a>
        <Spinner v-if="isLoading" />
        <a v-else @click="createOrEditUser" type="submit">
          <p>{{ getButtonCaption }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../components/SpinnerComp";
import commons from "../mixins/mixCommons";

export default {
  mixins: [commons],
  props: {
    mode: {
      type: String,
      default: "CREATION",
    },
    selectedShopCode: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      reRenderFlag: false,
      roles: [
        {
          roleName: "Ventas y Reportes",
          backendId: "SalesReports",
        },
        {
          roleName: "Clientes",
          backendId: "Customers",
        },
        {
          roleName: "Productos",
          backendId: "Products",
          subRoles: [
            {
              roleName: "Disponibilidad de Productos",
              backendId: "ProductsAvailability",
            },
            {
              roleName: "Adm. de Productos",
              backendId: "ProductsAdministration",
            },
            {
              roleName: "Adm. de Stock",
              backendId: "ProductsStockAdministration",
            },
            {
              roleName: "Costo de Mercadería",
              backendId: "ProductsCost",
            },
          ],
        },
        {
          roleName: "Caja",
          backendId: "Cashbox",
        },
        {
          roleName: "Usuarios y Configuraciones",
          backendId: "Users",
          subRoles: [
            {
              roleName: "Usuarios",
              backendId: "UsersAdministration",
            },
            {
              roleName: "Configuraciones",
              backendId: "SettingsAdministration",
            },
          ],
        },
        {
          roleName: "Proveedores",
          backendId: "Providers",
        },
        {
          roleName: "Auditoría de Cambios",
          backendId: "Events",
        },
        {
          roleName: "Acceso a API",
          backendId: "ApiUser"
        }
      ],
      localUser: { ...this.user },
      hasSomethingChanged: false,
    };
  },
  created() {
    this.localUser = { ...this.user };
  },
  watch: {
    user: {
      handler(newValue) {
        this.localUser = { ...newValue };
      },
      deep: true,
    },
  },
  computed: {
    selectedPdvs() {
      return this.$store.getMerchantTags;
    },
    getButtonCaption() {
      return this.mode === "EDIT" ? "Guardar Usuario" : "Crear Usuario";
    },
    getTitle() {
      return this.mode === "EDIT" ? "Editar" : "Crear";
    },
    isUserNameEditable() {
      return this.mode !== "EDIT";
    },
    isUserMailEditable() {
      return this.user.mail !== this.$store.getAuthUser.user.name;
    },
    getAddedOrEditedCaption() {
      return this.mode === "EDIT" ? "editado" : "agregado";
    },
  },
  methods: {
    onHandleChange() {
      this.hasSomethingChanged = true;
    },
    isShopSwitchChecked(shopCode) {
      return this.localUser.relatedShopCodes?.includes(shopCode) ?? false;
    },
    isRoleSwitchChecked(roleName) {
      return this.localUser.roles?.includes(roleName) ?? false;
    },
    verifyIfAllProductSubRolesAreCheckedAndCheckProductRole() {
      const userRoles = [...this.localUser.roles];
      const rolesToCheck = [
        "ProductsAvailability",
        "ProductsAdministration",
        "ProductsStockAdministration",
        "ProductsCost",
      ];
      const allRolesIncluded = rolesToCheck.every((item) =>
        userRoles.includes(item)
      );

      if (allRolesIncluded && !userRoles.includes("Products")) {
        userRoles.push("Products");
      } else if (!allRolesIncluded) {
        this.localUser.roles = userRoles.filter((role) => role !== "Products");
      }

      this.localUser.roles = userRoles;
    },
    verifyIfAllUsersSubRolesAreCheckedAndCheckProductRole() {
      const userRoles = [...this.localUser.roles];
      const rolesToCheck = ["UsersAdministration", "SettingsAdministration"];
      const allRolesIncluded = rolesToCheck.every((item) =>
        userRoles.includes(item)
      );

      if (allRolesIncluded && !userRoles.includes("Users")) {
        userRoles.push("Users");
      } else if (!allRolesIncluded) {
        this.localUser.roles = userRoles.filter((role) => role !== "Users");
      }

      this.localUser.roles = userRoles;
    },
    onHandleRoleSwitch(roleName, subRoles = null) {
      if (!this.localUser.roles) {
        this.localUser.roles = [];
      }

      if (!this.localUser.roles.includes(roleName)) {
        this.localUser.roles.push(roleName);
        this.addSubrole(subRoles);
      } else {
        this.localUser.roles = this.localUser.roles.filter(
          (role) => role !== roleName
        );

        if (subRoles?.length) {
          subRoles.forEach((subRole) => {
            this.localUser.roles = this.localUser.roles.filter(
              (role) => role !== subRole.backendId
            );
          });
        }
      }

      this.verifyIfAllProductSubRolesAreCheckedAndCheckProductRole();
      this.verifyIfAllUsersSubRolesAreCheckedAndCheckProductRole();
      this.reRenderFlag = !this.reRenderFlag;
      this.onHandleChange();
    },

    addSubrole(subRoles) {
      if (!subRoles) return;

      if (!this.localUser.roles) {
        this.localUser.roles = [];
      }

      subRoles.forEach((subRole) => {
        if (!this.localUser.roles.includes(subRole.backendId)) {
          this.localUser.roles.push(subRole.backendId);
        }
      });
    },
    onHandleShopSwitch(shopCode) {
      if (!this.localUser.relatedShopCodes) {
        this.localUser.relatedShopCodes = [];
      }

      if (!this.localUser.relatedShopCodes.includes(shopCode)) {
        this.localUser.relatedShopCodes.push(shopCode);
      } else {
        this.localUser.relatedShopCodes = this.localUser.relatedShopCodes.filter(
          (code) => code !== shopCode
        );
      }
    },
    closeModal() {
      this.localUser = { ...this.user };
      this.hasSomethingChanged = false;
      document.body.style.overflow = "auto";
      this.$emit("onCloseModal");
    },
    deleteUser() {
      this.isLoading = true;
      this.$emit("deleteUser", this.localUser);
      this.localUser = { ...this.user };
    },
    createOrEditUser() {
      if (!this.localUser.username) {
        this.showError(`El Nombre de usuario no puede ser vacío`);
        return;
      }
      if (!this.localUser.mail) {
        this.showError(`El mail de usuario no puede ser vacío`);
        return;
      }
      if (
        !this.localUser.relatedShopCodes ||
        this.localUser.relatedShopCodes.length === 0
      ) {
        this.showError(`Se debe asociar al usuario en al menos un comercio`);
        return;
      }
      if (!this.localUser.role) {
        this.localUser.role = "WEB";
      }
      if (!this.hasSomethingChanged) {
        this.showSuccess(
          `Usuario ${this.getAddedOrEditedCaption} correctamente`
        );
        this.closeModal();
        return;
      }
      this.$store.initLoading(this);

      const request = this.mode === "EDIT" ? axios.put : axios.post;
      const url = `/api/userWeb`;

      this.localUser.shopCode = this.selectedShopCode;
      this.localUser.availableShopCodes = this.$store
        .getMerchantTags.map((i) => i.shopCode);

      this.isLoading = true;
      request(url, this.localUser)
        .then((res) => {
          this.$store.finishLoading(this);
          if (res.data.responseCode === 0) {
            this.showSuccess(
              `Usuario ${this.getAddedOrEditedCaption} correctamente`
            );
            this.closeModal();
          } else {
            this.showError(res.data.responseMessage);
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.showErrors(err.response);
          this.isLoading = false;
        });
    },
  },
  components: {
    Spinner,
  },
};
</script>
